.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
}

.btn--outline {
    background-color: transparent;
    color: var(--yellow);
    border: 1px solid var(--yellow);
    transition: all 0.3s ease-out;
}

.btn--small {
    padding: 6px 18px;
    font-size: 16px;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover, .btn--small:hover {
    border-radius: 0px;
    box-shadow: 0.2em 0.2em 0px black;
    transition: all 0.3s ease-out;
}

.yellow {
    color: black;
    background-color: var(--yellow);
}

.red {
    color: black;
    background-color: var(--red);
}

.green {
    color: white;
    background-color: var(--green);
}

.orange {
    color: black;
    background-color: var(--orange);
}

.blue {
    color: black;
    background-color: var(--blue);
}