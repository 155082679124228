.hidden {
    display: none;
}

.form-div {
    text-align: center;
}

.form-div > form {
    margin-top: 65px;
    padding: 25px;
    width: 70vw;
    background-color: var(--green);
    display: inline-block;
    box-shadow: 4px 4px 0px black;
}

.form-label {
    font-weight: 600;
    width: fit-content;
}


.text-input {
    width: 60%;
    border: 2px solid black;
    margin: 20px 0px;
    padding: 14px;
    border-radius: 4px;
}

.text-input:focus {
    box-shadow: 4px 4px 0px black;
}

textarea {

    width: 60vw;
    min-width: 240px;
    height: 40vh;
}

@media screen and (max-width: 960px) {
    .form-div > form {
        margin-top: 20px;
        border-radius: 0;
        padding: 12px;
        width: 90vw;
    }

    .text-input {
        margin: 10px 0px;
        width: 90%;
        padding: 8px;
    }
}