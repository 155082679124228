body {
    background-color: lightgray;
    overflow-x: hidden;
}

.back-media {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.body-header-container {
    width: 100%;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: row;
    align-items: center;
    object-fit: contain;

}

.bio-container {
    background-color: white;
    width: max(30%, 500px);
    padding: 40px 60px;
    margin-left: 10%;
}

.bio-header {
    font-family: "Roboto Mono";
    padding-left: 20px;
    font-size: 150%;
}

.bio-text {
    font-family: "Roboto Mono";
    
}

.personal-photo {
    position: absolute;
    right: calc( -1 * max(0px, 1440px - 100vw) * 0.5);
    top: 60px;
    height: calc(100% - 60px);
    z-index: -1;
}

.logo-image {
    width: min(480px, 100%);
    height: auto;
}

.button-container {
    display: flex;
    padding-top: 20px;
    justify-content: space-around;
}

.button-link {
    text-align: justify;
}

.bio-container > ul {
    padding-left: 60px;
}

.bio-container > ul > li {
    padding-left: 20px;
}

.bio-container > p {
    margin-bottom: 1em;
    padding-left: 40px;
    max-width: 60ch;
}

@media screen and (max-width: 960px) {

    .body-header-container {
        flex-direction: column-reverse;
        height: unset;
    }

    .logo-image {
        width: 100%;
    }

    .bio-container {
        width: 100%;
        padding: 20px 30px;
        margin-left: 0px;
    }

    .bio-container > p {
        padding-left: 20px;
    }

    .personal-photo {
        position: relative;
        top: unset;
        right: unset;
        height: auto;
        width: 100%;
    }

    .bio-header {
        font-size: max(1rem, 18px);
    }

    .bio-text {
        font-size: max(0.5rem, 12px);
    }

    .button-link {
        font-size: 12px;
    }

}